import * as React from 'react';
import { graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { mapEdgesToNodes } from '../utils';
import BlogPostGrid from '../components/BlogPostGrid';

interface Props {
  data: any;
}

// markup
const IndexPage = ({ data }: Props) => {
  const posts = mapEdgesToNodes(data.posts.edges);

  return (
    <Layout pageTitle="Home">
      <SEO title="Wehkamp Tech Blog" description="Wehkamp Tech Blog" />

      <section>
        <Container>
          <BlogPostGrid posts={posts} />
        </Container>
      </section>
    </Layout >
  );
};

export const query = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    posts: allMdx(filter: {fileAbsolutePath: {regex: "/\/blog\//"}}, sort: {fields: [frontmatter___publishedDate], order: DESC}, limit: 9) {
      edges {
        node {
          ...BlogPostPreview
        }
      }
    }
  }
`;

export default IndexPage;
